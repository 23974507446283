import { CalendarTwoTone, HourglassTwoTone, MobileTwoTone, PlusCircleTwoTone, RobotOutlined } from "@ant-design/icons";
import { InputNumber, Divider, Button, Slider, message, Modal, Form, Tooltip, Alert } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useGlobalState } from "../../components/global";
import { FormScreenChannel } from "./FormScreenChannel";
import { useParams } from "react-router-dom";
import { FormScreenGroup } from "./FormScreenGroup";
import "./index.css"
import { ScreenItem } from "./ScreenItem";
import useLocalStorageState from "use-local-storage-state";

export default function LaunchScreen() {
    const { jsonRequest } = useGlobalState();
    const [showAlert, setShowAlert] = useLocalStorageState<boolean>("LaunchScreen/ShowAlert", { defaultValue: true })
    const [screen, setScreen] = useState<{ width: number, height: number, model: string }>({ width: 360, height: 780, model: "360780" })
    const [zoomRatio, setZoomRatio] = useState<number>(1)
    const [data, setData] = useState<any>();
    const { appid } = useParams()
    const [versions, setVersions] = useState<any>({})


    useEffect(() => {
        handleReload()
        jsonRequest.application("get", "zy:services::release", `/api/distribution/${appid}/versions`, {})
            .then(({ body }) => {
                setVersions(body.data)
            })

        return () => {
        }
    }, [])

    const handleReload = () => {
        jsonRequest.application("get", "zy:services::release", `/api/launch_screen/${appid}`, {})
            .then(({ body }) => {
                setData(body.data)
            })
    }

    const [addChannelFormRef] = Form.useForm()

    // 添加渠道
    const handleClickAddChannel = (launch_screen_id: any) => {
        Modal.confirm({
            title: '添加独立渠道',
            content: <FormScreenChannel form={addChannelFormRef} initial={null} />,
            onOk: (close) => {
                const form = addChannelFormRef
                form
                    .validateFields()
                    .then((values: any) => {

                        values = { ...values, action: { ...values.action, data: JSON.parse(values.action.data || "{}") } }
                        jsonRequest.application("post", "zy:services::release", `/api/launch_screen/${appid}/${launch_screen_id}/channel`, values)
                            .then(({ ok }) => {
                                if (ok) {
                                    close()
                                    message.success("添加成功")
                                    form.resetFields();
                                    handleReload()
                                }
                            })
                    })
                    .catch((info: any) => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }

    const [screenFormRef] = Form.useForm()

    // 添加渠道
    const handleClickAddScreen = () => {
        Modal.confirm({
            width: 600,
            title: '添加开屏页',
            content: <FormScreenGroup versions={versions} screen={{}} form={screenFormRef} appid={appid} />,
            onOk: (close) => {
                const form = screenFormRef
                form
                    .validateFields()
                    .then((values: any) => {
                        values = { ...values, action: { ...values.action, data: JSON.parse(values.action.data || "{}") } }

                        jsonRequest.application("post", "zy:services::release", `/api/launch_screen/${appid}`, values)
                            .then(({ ok }) => {
                                if (ok) {
                                    close()
                                    message.success("添加成功")
                                    form.resetFields();
                                    handleReload()
                                }
                            })
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }

    const handleClickDeleteGroup = (launch_screen_id: any) => {
        Modal.confirm({
            title: '删除确认',
            content: <>
                删除组无法恢复，确认继续？
            </>,
            onOk: (close) => {
                jsonRequest.application("delete", "zy:services::release", `/api/launch_screen/${appid}/${launch_screen_id}`, {})
                    .then(({ ok }) => {
                        if (ok) {
                            close()
                            message.success("删除组成功")
                            handleReload()
                        }
                    })
                return false
            }
        });
    }


    return <div className="page release-launch-screen">
        <h1><RobotOutlined /> 开屏页</h1>
        {showAlert && <Alert onClose={() => setShowAlert(false)} type="info" message="使用注意" description="没有声明的渠道，会使用默认开屏页。一旦某渠道有声明，则所有数据完全覆盖使用" showIcon closeIcon closable />}
        <div className="content">
            <div>
                <p style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <InputNumber value={screen.width} addonBefore="宽" onChange={v => v && setScreen({ ...screen, width: v })} />
                        <Divider type="vertical" />
                        <InputNumber value={screen.height} addonBefore="高" onChange={v => v && setScreen({ ...screen, height: v })} />
                    </div>
                    <Divider type="vertical" />
                    <Button.Group>
                        <Button type={screen.model === "360780" ? "primary" : "default"} onClick={() => setScreen({ model: "360780", width: 360, height: 780 })}>安卓多数机型、iPhone Mini</Button>
                        <Button type={screen.model === "iphonepro" ? "primary" : "default"} onClick={() => setScreen({ model: "iphonepro", width: 390, height: 844 })}> iPhone普通款、Pro </Button>
                        <Button type={screen.model === "iphonepromax" ? "primary" : "default"} onClick={() => setScreen({ model: "iphonepromax", width: 428, height: 926 })}>iPhone Pro Max</Button>
                        <Button type={screen.model === "iphonese" ? "primary" : "default"} onClick={() => setScreen({ model: "iphonese", width: 375, height: 667 })}>iPhone SE</Button>
                    </Button.Group>
                </p>
                <p>
                    <Slider
                        min={0.3}
                        max={2.5}
                        marks={{ 1: "x 1", 0.5: "x 0.5", 0.8: "x 0.8", 2: "x 2" }}
                        onChange={setZoomRatio}
                        value={zoomRatio}
                        step={0.01}
                    />
                </p>
                <div className="launch-screen-group" onClick={() => handleClickAddScreen()} style={{
                    height: 80,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer"
                }} >
                    <span style={{ fontSize: 48 }}>
                        <PlusCircleTwoTone />
                    </span>
                </div>
                {
                    data?.map((launch: any) => {
                        return <div className="launch-screen-group" key={`launch-group-${launch.id}`}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <h3 style={{ margin: 0 }}>{launch.name}</h3>
                                    <Divider type="vertical" />
                                    <small><CalendarTwoTone /> {dayjs(launch.begin_at).add(8, 'hour').format("YYYY-MM-DD HH:mm")} - {dayjs(launch.end_at).add(8, 'hour').format("YYYY-MM-DD HH:mm")}</small>
                                    <Divider type="vertical" />
                                    <small><MobileTwoTone /> <Tooltip title="最小版本号">{versions[launch.min_version_code] ? <span>{versions[launch.min_version_code]} ({launch.min_version_code})</span> : launch.min_version_code}</Tooltip> 至 <Tooltip title="最大版本号">{versions[launch.max_version_code] ?? launch.max_version_code}</Tooltip></small>
                                    <Divider type="vertical" />
                                    <small><Tooltip title="最大展示次数"><HourglassTwoTone /> {launch.max_display_times} 次</Tooltip></small>
                                </div>
                                <div>
                                    {/* <Button.Group size="small">
                                        <Button onClick={() => handleClickFilterUserGroup(launch.id, 0)} type={launch.filter?.user_group?.indexOf(0) > -1 ? "primary" : "dashed"}>潜客</Button>
                                        <Button onClick={() => handleClickFilterUserGroup(launch.id, 1)} type={launch.filter?.user_group?.indexOf(1) > -1 ? "primary" : "dashed"}>会员</Button>
                                    </Button.Group> */}
                                    {/* <Divider type="vertical" /> */}
                                    <Button type="link" onClick={() => handleClickDeleteGroup(launch.id)} danger>删除组</Button>
                                </div>
                            </div>
                            <div className="launches">
                                <ScreenItem appid={appid!} launchScreenID={launch.id} defaultOrChannelItem={launch} handleReload={handleReload} zoomRatio={zoomRatio} simScreen={screen} versions={versions} />
                                {launch.channels.map((channel: any) => <ScreenItem key={channel.id} appid={appid!} launchScreenID={launch.id} isChannel defaultOrChannelItem={channel} handleReload={handleReload} zoomRatio={zoomRatio} simScreen={screen} versions={versions} />)}
                                <div className="launch-item add">
                                    <div>添加独立渠道</div>
                                    <div className="cover" onClick={() => handleClickAddChannel(launch.id)} style={{
                                        width: screen.width * zoomRatio,
                                        height: screen.height * zoomRatio,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        cursor: "pointer"
                                    }} >
                                        <span style={{ fontSize: 64 }}>
                                            <PlusCircleTwoTone />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </div >
    </div >
}