import { Form, Input, Switch } from "antd";
import React, { useEffect } from "react";
import PictureInput from "./medias/PictureInput";
import FileInput from "./medias/FileInput";
import MediaPictureInput from "./medias/MediaPictureInput";
import RichTextInput from "./medias/RichTextInput";
import TagsInput from "./medias/TagsInput";
import MultiInputGroup from "./MultiInputGroup";
import { useWatch } from "antd/lib/form/Form";
import PoetryInput from "./medias/PoetryInput";
import CompareInput from "./CompareInput";
import DosageInput from "./medias/DosageInput";
import AudioInput from "./medias/AudioInput";
import VideoInput from "./medias/video/VideoInput";
import MindMapInput from "./medias/MindMapInput";
import HistoryInput from "./medias/HistoryInput";
import OrderedMedia from "./OrderedMedia";
import HistoryTranslateInput from "./medias/HistoryTranslateInput";

const { TextArea } = Input;

export default function ContentFieldItem({ field, form, prefix, listName, fullPrefix }: any) {
    const { fieldName, mediaName, mediaType, mediaId, extData, isRequired } = field;
    const watchMediaName = useWatch([...listName || [], ...prefix, "mediaName"], form);

    const StringInput = ({ prefix }: any) => {
        const { inputPrompt } = extData ?? {};
        return <Form.Item name={[...prefix, "description"]} style={{ width: "80%" }} wrapperCol={{ span: 23 }}
                          rules={[{ required: isRequired, message: "不能为空" }]} initialValue={""}>
            <TextArea placeholder={inputPrompt} rows={1}
                      onChange={() => form.setFieldValue([...fullPrefix.slice(0, -1), "change"], true)} />
        </Form.Item>;
    };
    
    const MultiDosageInput = ({ prefix }: any) => {
        return <MultiInputGroup name={[...prefix, "list"]} extData={extData} addable divider
                                item={(index: any) =>
                                  <DosageInput prefix={[index]} form={form} index={index}
                                               fullPrefix={[...fullPrefix, "list", index]}
                                               listPathName={[...fullPrefix, "list"]} />} />;
    };

    const MultiHistoryInput = ({ prefix }: any) => {
        return <MultiInputGroup name={[...prefix, "list"]} extData={extData} addable removable divider
                                item={(index: any) =>
                                  <HistoryInput prefix={[index]} form={form} index={index}
                                                changePrefix={fullPrefix}
                                                fullPrefix={[...fullPrefix, "list", index]}
                                                listPathName={[...fullPrefix, "list"]} />} />;
    };

    const MultiHistoryTranslateInput = () => {
        return <MultiInputGroup name={[...prefix, "list"]} extData={extData} addable removable divider
                                item={(index: any) =>
                                  <HistoryTranslateInput prefix={[index]} form={form} index={index}
                                                         changePrefix={fullPrefix}
                                                         fullPrefix={[...fullPrefix, "list", index]}
                                                         listPathName={[...fullPrefix, "list"]} />} />;
    };

    const MultiCompareInput = ({ prefix }: any) => {
        return <MultiInputGroup name={[...prefix, "list"]} extData={extData} addable divider
                                item={(index: any) =>
                                  <CompareInput form={form} prefix={[index]} index={index}
                                                fullPrefix={[...fullPrefix, "list", index]}
                                                listPathName={[...fullPrefix, "list"]} />} />;
    };

    const MultiPictureInput = ({ prefix }: any) => <>
        <Form.Item label="是否渐进图" name={[...prefix, "progressive"]}
                   rules={[{ required: isRequired, message: "不能为空" }]}>
            <Switch />
        </Form.Item>
        <MultiInputGroup name={[...prefix, "pictures"]} extData={extData} addable divider
                         item={(index: any) =>
                           <MediaPictureInput form={form} prefix={[index]} field={field} index={index}
                                              fullPrefix={[...fullPrefix, "pictures", index]}
                                              listPathName={[...fullPrefix, "pictures"]} />} />
    </>;

    const SlideInput = ({ prefix }: any) => {
        return <>
            <Form.Item label="间隔时间" name={[...prefix, "duration"]}
                       rules={[{ required: isRequired, message: "不能为空" }]}>
                <Input placeholder="请输入间隔时间" />
            </Form.Item>
            <MultiInputGroup name={[...prefix, "pictures"]} addSuffix={fieldName || mediaName} extData={extData}
                             addable divider
                             item={(index: any) =>
                               <MediaPictureInput form={form} prefix={[index]} field={field} index={index}
                                                  fullPrefix={[...fullPrefix, "pictures", index]}
                                                  listPathName={[...fullPrefix, "pictures"]} />} />
        </>;
    };

    const MultiAudioInput = ({ prefix }: any) =>
      <MultiInputGroup name={[...prefix, "audios"]} addSuffix={fieldName || mediaName} extData={extData}
                       addable divider
                       item={(index: any) =>
                         <AudioInput form={form} prefix={[index]} field={field} index={index}
                                     fullPrefix={[...fullPrefix, "audios", index]}
                                     listPathName={[...fullPrefix, "audios"]} />} />;

    const MultiVideoInput = ({ prefix }: any) =>
      <MultiInputGroup name={[...prefix, "videos"]} addSuffix={fieldName || mediaName} extData={extData}
                       addable divider
                       item={(index: any) =>
                         <VideoInput form={form} prefix={[index]} field={field} index={index}
                                     fullPrefix={[...fullPrefix, "videos", index]}
                                     listPathName={[...fullPrefix, "videos"]} />} />;

    const TreeDInput = ({ prefix = [] }: any) => {
        return <>
            <Form.Item label={`模型名称`} name={[...prefix, "modelName"]}
                       rules={[{ required: isRequired, message: "不能为空" }]}>
                <Input placeholder="请输入模型名称" />
            </Form.Item>
            <Form.Item label={`模型描述`} name={[...prefix, "description"]}
                       rules={[{ required: isRequired, message: "不能为空" }]}>
                <Input placeholder="请输入模型描述" />
            </Form.Item>
            <PictureInput form={form} prefix={prefix} field={field} labelPre="模型封面" />
            <FileInput form={form} labelPre="android模型" prefix={prefix} fullPrefix={fullPrefix}
                       listName={listName} namePre="android"
                       type="other" field={field} />
            <FileInput form={form} labelPre="ios模型" prefix={prefix} fullPrefix={fullPrefix}
                       namePre="ios" type="other" field={field} />
        </>;
    };

    const MultiLinkInput = ({ prefix, inner }: any) => (
      <MultiInputGroup name={[...prefix, "links"]} addSuffix={fieldName || mediaName} extData={extData}
                       addable divider
                       item={(index: any) => <OrderedMedia index={index} form={form}
                                                           listPathName={[...fullPrefix, "links"]}>
                           <Form.Item label={`链接标题`} name={[index, "linkName"]} initialValue={""}
                                      rules={[{ required: isRequired, message: "不能为空" }]}>
                               <Input placeholder="请输入链接标题" />
                           </Form.Item>
                           {!inner &&
                             <Form.Item label={`链接描述`} name={[index, "linkDescription"]} initialValue={""}>
                               <Input placeholder="请输入链接描述" />
                             </Form.Item>}
                           <Form.Item label={`链接地址`} name={[index, "linkPath"]} initialValue={""}
                                      rules={[{ required: isRequired, message: "不能为空" }]}>
                               <Input placeholder="请输入链接地址" />
                           </Form.Item>
                           {inner && <Form.Item label={`链接参数`} name={[index, "linkParam"]} initialValue={""}>
                             <Input placeholder="请输入链接参数" />
                           </Form.Item>}
                       </OrderedMedia>} />
    );

    const MediaComponent = () => {
        switch (mediaType) {
            case "string":
                return <StringInput prefix={prefix} />;
            case "rich_text":
                return <RichTextInput field={field} prefix={prefix} fullPrefix={fullPrefix} form={form} />;
            case "picture":
                return <MediaPictureInput form={form} prefix={prefix} fullPrefix={fullPrefix} field={field} />;
            case "multi_picture":
                return <MultiPictureInput prefix={prefix} />;
            case "slide":
                return <SlideInput prefix={prefix} />;
            case "audio":
                return <AudioInput form={form} prefix={prefix} fullPrefix={fullPrefix} field={field} />;
            case "multi_audio":
                return <MultiAudioInput prefix={prefix} />;
            case "video":
                return <VideoInput form={form} prefix={prefix} fullPrefix={fullPrefix} field={field} />;
            case "multi_video":
                return <MultiVideoInput prefix={prefix} />;
            case "3D":
                return <TreeDInput prefix={prefix} />;
            case "tags":
            case "tags_content":
                return <TagsInput prefix={prefix} fullPrefix={fullPrefix} field={field} form={form} />;
            case "inter_link":
                return <MultiLinkInput prefix={prefix} inner />;
            case "exter_link":
                return <MultiLinkInput prefix={prefix} />;
            case "poetry":
                return <PoetryInput prefix={prefix} field={field} form={form} fullPrefix={fullPrefix} />;
            case "equal_diff":
                return <MultiCompareInput prefix={prefix} />;
            case "dosage":
                return <MultiDosageInput prefix={prefix} />;
            case "mind_map":
                return <MindMapInput prefix={prefix} fullPrefix={fullPrefix} form={form} field={field} />;
            case "history":
                return <MultiHistoryInput prefix={prefix} />;
            case "history_translate":
                return <MultiHistoryTranslateInput />;
        }
        return <></>;
    };

    useEffect(() => form.setFieldValue([...fullPrefix, "mediaName"], mediaName), [mediaName]);

    return <>
        <Form.Item name={[...prefix, "mediaName"]} hidden initialValue={mediaName || fieldName}><Input /></Form.Item>
        <Form.Item name={[...prefix, "mediaId"]} hidden initialValue={mediaId}><Input /></Form.Item>
        {(watchMediaName || mediaType) && <MediaComponent />}
    </>;
}