import React, { useEffect, useState } from "react";
import { Form, Input, message, Select } from "antd";

import { useGlobalState } from "../../../../../components/global";
import FileInput from "../FileInput";
import OssPath from "../../../../../components/oss_path";
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

export default function VideoUploadForm({ form, detail, rejectDefinitions = [] }: any) {
    const { currentTenant, jsonRequest } = useGlobalState();
    const [definitions, setDefinitions]: any = useState([]);

    useEffect(() => {
        form.resetFields();
        getDefinition();
        detail.videoPath && initFile();
    }, []);

    const initFile = () => {
        const { videoName, videoPath, id } = detail;
        form.setFieldValue("file", [{ name: videoName, url: videoPath, uid: id, status: "done" }]);
    };

    const getDefinition = () => {
        jsonRequest.post("/api/request", {
            path: "/v1/enums",
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: { type: "video_definition" },
        }).then((resp: any) => resp.json())
          .then(({ data }) => {
              setDefinitions(data);
          });
    };

    const onFileChange = (file: any) => {
        if (!file) return;
        // 设置音频名称
        const name = file.name.substring(0, file.name.lastIndexOf("."));
        form.setFieldValue("videoName", name);
        
        if (!detail.id)
            form.setFieldValue("id", uuidv4());
        // 设置视频时长
        setVideoInfo(file.url);
        // 设置m3u8地址
        setHlsUrl(file.originFileObj?.url);
    };

    const setHlsUrl = async (url: string) => {
        const { data } = await jsonRequest.post("/api/request", {
            path: `/v1/aliyun/video/hls`,
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: { objectKey: url },
        })
          .then((resp: any) => resp.json());
        const hlsUrl = await OssPath(jsonRequest, currentTenant, "OssM3u8Path") + btoa(data) + ".m3u8";
        form.setFieldValue("hlsUrl", hlsUrl);
    };

    const setVideoInfo = (url: any) => {
        const videoElement: HTMLVideoElement = document.createElement("video");
        videoElement.src = url;
        videoElement.addEventListener("loadedmetadata", (e) => {
            // 获取时长
            const time = Math.floor(videoElement.duration) || 0;
            form.setFieldValue("duration", time);
            // 获取分辨率
            form.setFieldValue("realDefinitionValue", `${videoElement.videoWidth}x${videoElement.videoHeight}`);
        });
        videoElement.addEventListener("error", (e) => {
            message.error("获取视频时长失败");
        });
    };

    const onDefinitionChange = (v: any) => {
        form.setFieldValue("definition", definitions?.find((e: any) => e.enumKey === v).enumName || "");
    };

    return <Form form={form} labelCol={{ span: 4 }} wrapperCol={{ span: 18 }} autoComplete="off" initialValues={detail}>
        <Form.Item name="id" hidden><Input /></Form.Item>
        <Form.Item name="index" hidden><Input /></Form.Item>
        <Form.Item name="videoName" hidden><Input /></Form.Item>
        <Form.Item name="hlsUrl" hidden><Input /></Form.Item>
        <Form.Item name="definition" hidden><Input /></Form.Item>
        <Form.Item label="分辨率" name="definitionValue" rules={[{ required: true, message: "不能为空" }]}>
            <Select onChange={onDefinitionChange} options={definitions?.map((e: any) => ({
                value: e.enumKey,
                label: e.enumName,
                disabled: rejectDefinitions.includes(e.enumKey),
            }))} />
        </Form.Item>
        <FileInput form={form} labelPre="视频" field={{ isRequired: true }} pathName="videoPath"
                   type="video" accept="video/*" onChange={onFileChange} />
        <Form.Item label="真实分辨率" name="realDefinitionValue">
            <Input disabled />
        </Form.Item>
        <Form.Item label="视频时长" name="duration" rules={[{ required: true, message: "不能为空" }]}>
            <Input />
        </Form.Item>
    </Form>;
}