import { Form, Input, Switch } from "antd";
import CustomQuill from "../../../../components/CustomQuill";
import { useGlobalState } from "../../../../components/global";
import ContentImageUploader from "../../../components/ContentImageUploader";
import React, { useState } from "react";

const { TextArea } = Input;

export default function RichTextInput({ field, prefix, form, fullPrefix }: any) {
    const { extData, isRequired } = field || {};
    const [showCode, setShowCode]: any = useState(false);

    const { jsonRequest, currentTenant } = useGlobalState();

    const imgUploadApi = async (fd: any) => {
        return await ContentImageUploader(jsonRequest, currentTenant, fd.get("file"));
    };

    const onChanged = (v: any) => {
        form.setFieldValue([...fullPrefix.slice(0, -1), 'change'], true);
    };

    return <>
        <Form.Item name={[...prefix.slice(0, -1), "change"]} initialValue={false} hidden><Input /></Form.Item>
        <Form.Item label="查看源码">
            <Switch defaultChecked={showCode} onChange={(v) => setShowCode(v)} />
        </Form.Item>
        <Form.Item name={[...prefix, "description"]} wrapperCol={{ span: 23 }} initialValue={""}
                   rules={[{ required: isRequired, message: "不能为空" }]}>
            {showCode ? <TextArea rows={3} /> :
              <CustomQuill imgUploadApi={imgUploadApi} onChange={onChanged} placeholder={extData?.inputPrompt} />}
        </Form.Item>
    </>;
}