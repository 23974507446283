import React, { useEffect, useState } from "react"
import { Table } from "antd"
import { Switch, Radio, Button, Form, InputNumber, Modal, Input, DatePicker, ConfigProvider, message, Select } from "antd"
import { CloudDownloadOutlined, PlusSquareTwoTone, DeleteOutlined, RocketOutlined, CloseCircleOutlined, CheckCircleOutlined, ApartmentOutlined } from '@ant-design/icons'
import zhCN from 'antd/lib/locale/zh_CN';
import dayjs from "dayjs"
import { Popover } from "antd"
import { QRCode } from "antd"
import { useGlobalState } from "../../components/global";
import { useReleaseState } from "../state/release_state";
import { useParams } from "react-router-dom";

const { Column } = Table

export default function Distribution(props: any) {

    const { jsonRequest } = useGlobalState()
    const { channels } = useReleaseState()
    const { appid } = useParams()

    let [data, setData] = useState<{ entries: Array<any> | null, page_number: number, page_size: number, total_entries: number }>({ entries: null, page_number: 1, page_size: 20, total_entries: 0 })
    let [newVersionForm] = Form.useForm()
    let [newVersionChannelForm] = Form.useForm()

    useEffect(() => {
        handlePageTo(data.page_number, data.page_size)
    }, [])

    const handlePageTo = (page: number, size: number) => {
        jsonRequest.application("get", "zy:services::release", `/api/distribution/${appid}`, { page, size })
            .then(({ body }: any) => setData(body.data))
    }

    const handleCreate = async (values: any) => {
        const resp = await jsonRequest.application("post", "zy:services::release", `/api/distribution/${appid}`, { ...values, appid })
        handlePageTo(data.page_number, data.page_size)
        return resp
    }

    const handleCreateChannel = async (version_code: number, values: any) => {
        const resp = await jsonRequest.application("post", "zy:services::release", `/api/distribution/${appid}/${version_code}/channel`, { ...values, appid })
        handlePageTo(data.page_number, data.page_size)
        return resp
    }

    const handleDrop = (version_code: number) => {
        Modal.confirm({
            title: '确认',
            content: "删除会被记录在操作日志里，请谨慎操作。",
            onOk: (close) => {
                jsonRequest.application("delete", "zy:services::release", `/api/distribution/${appid}/${version_code}`, {})
                    .then(({ ok }) => {
                        if (ok) {
                            handlePageTo(data.page_number, data.page_size)
                            message.success({ content: '已删除', duration: 3 })
                            close()
                        }
                    })
            }
        })
        handlePageTo(data.page_number, data.page_size)
    }

    const handleDropChannel = (version_code: number, channel_key: string) => {
        Modal.confirm({
            title: '确认',
            content: "删除版本会被记录在操作日志里，请谨慎操作。",
            onOk: (close) => {
                jsonRequest.application("delete", "zy:services::release", `/api/distribution/${appid}/${version_code}/channel/${channel_key}`, {})
                    .then(({ ok }) => {
                        if (ok) {

                            handlePageTo(data.page_number, data.page_size)
                            message.success({ content: '已删除版本', duration: 3 })
                            close()
                        }
                    })
            }
        })
        handlePageTo(data.page_number, data.page_size)
    }


    const handleUpdateField = async (version_code: number, field: string, value: any) => {
        await jsonRequest.application("put", "zy:services::release", `/api/distribution/${appid}/${version_code}/${field}`, { value })
        handlePageTo(data.page_number, data.page_size)
    }

    const handleUpdateChannelField = async (channel: string, version_code: number, field: string, value: any) => {
        await jsonRequest.application("put", "zy:services::release", `/api/distribution/${appid}/${version_code}/${channel}/${field}`, { value })
        handlePageTo(data.page_number, data.page_size)
    }

    // 添加版本
    const handleNewVersionAdd = () => {
        let minVersionCode = data?.entries?.[0]?.version_code || 1;
        Modal.confirm({
            width: 800,
            title: '新增版本',
            content: <ConfigProvider locale={zhCN}>
                <Form form={newVersionForm} name="basic" labelCol={{ span: 4 }} wrapperCol={{ span: 18 }} autoComplete="off">
                    <Form.Item label="版本名" name="version_name" rules={[{ required: true, message: '必填' }]}>
                        <Input placeholder="如: 1.0.0" prefix="v" />
                    </Form.Item>
                    <Form.Item label="版本号" name="version_code" initialValue={minVersionCode + 1} rules={[{ required: true, message: '必填' }]}>
                        <InputNumber min={minVersionCode + 1} step={1} placeholder={`最小${minVersionCode + 1}`} />
                    </Form.Item>
                    <Form.Item label="发行时间" name="released_at" initialValue={dayjs()} rules={[{ required: true, message: '必填' }]} >
                        <DatePicker format="YYYY-MM-DD" />
                    </Form.Item>
                    <Form.Item label="下载地址" name="uri" rules={[{ required: true, message: '必填' }]} help="直接下载的地址">
                        <Input prefix="https://" />
                    </Form.Item>
                    <Form.Item label="状态" name="status" initialValue="pending" rules={[{ required: true, message: '必填' }]} >
                        <Radio.Group buttonStyle="solid">
                            <Radio.Button value="pending">审核中</Radio.Button>
                            <Radio.Button value="released">已上线</Radio.Button>
                            <Radio.Button value="unavailable">下架</Radio.Button>
                            <Radio.Button value="pending_cheating">审核模式</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="版本描述" name="description" rules={[{ required: true, message: '必填' }]} >
                        <Input.TextArea autoSize />
                    </Form.Item>
                </Form>
            </ConfigProvider>,
            onCancel: () => {
                newVersionForm.resetFields();
            },
            onOk: (close) => {
                const form = newVersionForm
                form
                    .validateFields()
                    .then(values => {
                        values = { ...values, version_name: `v${values.version_name}`, uri: `https://${values.uri}` }

                        handleCreate(values)
                            .then(() => {
                                close()
                                message.success("新增成功")
                                form.resetFields();
                            })
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }



    // 添加版本
    const handleNewVersionChannelAdd = (version_code: number) => {
        Modal.confirm({
            width: 600,
            title: '新增渠道版本',
            content: <ConfigProvider locale={zhCN}>
                <Form form={newVersionChannelForm} name="basic" labelCol={{ span: 4 }} wrapperCol={{ span: 18 }} autoComplete="off">
                    <Form.Item label="渠道" name="channel_key" rules={[{ required: true, message: '必填' }]}>
                        <Select showSearch optionLabelProp="label" options={channels!.map(item => {
                            return { label: item.name, key: item.key, value: JSON.stringify({ channel: item.key, platform: item.platform }) }
                        })}></Select>
                    </Form.Item>
                    <Form.Item label="发行时间" name="released_at" initialValue={dayjs()} rules={[{ required: true, message: '必填' }]} >
                        <DatePicker format="YYYY-MM-DD" />
                    </Form.Item>
                    <Form.Item label="下载地址" name="uri" rules={[{ required: true, message: '必填' }]} help="直接下载的地址">
                        <Input prefix="https://" />
                    </Form.Item>
                    <Form.Item label="状态" name="status" initialValue="pending" rules={[{ required: true, message: '必填' }]} >
                        <Radio.Group buttonStyle="solid">
                            <Radio.Button value="pending">审核中</Radio.Button>
                            <Radio.Button value="released">已上线</Radio.Button>
                            <Radio.Button value="unavailable">下架</Radio.Button>
                            <Radio.Button value="pending_cheating">审核模式</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </ConfigProvider>,
            onCancel: () => {
                newVersionChannelForm.resetFields();
            },
            onOk: (close) => {
                const form = newVersionChannelForm
                form
                    .validateFields()
                    .then(values => {
                        let channel_and_platform = JSON.parse(values.channel_key)
                        values = { ...values, uri: `https://${values.uri}`, channel_key: channel_and_platform.channel }

                        handleCreateChannel(version_code, values)
                            .then(() => {
                                close()
                                message.success("新增成功")
                                form.resetFields();
                            })
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }



    // // 测试版本更新
    // const handleVersionUpdateVersionSubmit = (version_code, channel_key) => {
    //     channel.push("test-version-update", { version_code, channel: channel_key })
    //         .receive("ok", (latestVersion) => {
    //             Modal.success({
    //                 title: `新版本${latestVersion.version_name}诚邀体验`,
    //                 content: <div>
    //                     <p>
    //                         介绍：<pre>{latestVersion.description}</pre>
    //                     </p>
    //                     <p>
    //                         强制更新： {latestVersion.force_update ? <CheckCircleOutlined style={{ color: "green" }} /> : <CloseCircleOutlined style={{ color: "red" }} />}
    //                     </p>
    //                     <p>
    //                         发行时间: {dayjs(latestVersion.released_at).add(8, "hour").format("YYYY-MM-DD")}
    //                     </p>
    //                     <p>
    //                         数据: <pre><code>{JSON.stringify(latestVersion, null, 2)}</code></pre>
    //                     </p>

    //                 </div>
    //             })
    //         })
    // }

    return <div className="page operation version">
        <h1><ApartmentOutlined /> 分发 / 版本</h1>

        <div className="actions" style={{ display: "flex" }}>
            <Button icon={<PlusSquareTwoTone />} type="link" onClick={handleNewVersionAdd}>
                新增版本
            </Button>
        </div>
        <br />
        <div style={{ minWidth: 1500 }}>
            {!!data.entries &&
                <Table dataSource={data.entries} key="versions-table" rowKey={item => item.version_code}
                    scroll={{ y: document.documentElement.clientHeight - 350 }}
                    size="middle"
                    rowClassName="version-row"
                    expandable={{
                        rowExpandable: (item) => (item?.channels || []).length > 0,
                        defaultExpandAllRows: true,
                        expandedRowRender: (item) => {
                            return <div style={{ padding: "20px 0 20px 32px" }} key={item.channel_key}>
                                <Table dataSource={item.channels} pagination={false} bordered rowKey={item => item.channel_key} size="middle">
                                    <Column title="渠道" dataIndex="channel_key" key="channel_key" width={100} align="center" />
                                    <Column title="平台类型" dataIndex="platform" key="platform" width={100} align="center" />
                                    <Column title="发行日期" dataIndex="released_at" key="released_at" width={150} render={(text) => dayjs(`${text}`).add(8, "hour").format("YYYY-MM-DD")} />
                                    <Column title="下载地址" dataIndex="uri" key="uri" align="center" width={100} render={(value) => <Popover content={<QRCode value={value}></QRCode>}><a href={value} target="_blank"></a><a href={value} target="_blank"><CloudDownloadOutlined /></a></Popover>} />
                                    <Column title="状态" dataIndex="status" key="status" width={300} render={(value, item: any) => <Radio.Group onChange={(e) => handleUpdateChannelField(item.channel_key, item.version_code, "status", e.target.value)} size="small" value={value} buttonStyle="solid">
                                        <Radio.Button value="pending">审核中</Radio.Button>
                                        <Radio.Button value="released">已上线</Radio.Button>
                                        <Radio.Button value="unavailable">下架</Radio.Button>
                                        <Radio.Button value="pending_cheating">审核模式</Radio.Button>
                                    </Radio.Group>} />
                                    <Column title="强制升级到最新版" width={150} dataIndex="deprecated" key="deprecated" render={(value, item: any) => <Switch onChange={(v) => handleUpdateChannelField(item.channel_key, item.version_code, "deprecated", v)} checked={value} unCheckedChildren="否" checkedChildren="是" size="small" ></Switch>} />
                                    <Column title="手动接受会员协议" dataIndex="manual_membership_agreement" key="manual_membership_agreement" render={(value, item: any) => <Switch onChange={(v) => handleUpdateChannelField(item.channel_key, item.version_code, "manual_membership_agreement", v)} checked={value} unCheckedChildren="否" checkedChildren="是" size="small" ></Switch>} />

                                    <Column title="操作" width={330} render={(text, item: any) => <>

                                        <Button size="small" type="link" danger icon={<DeleteOutlined />} onClick={() => handleDropChannel(item.version_code, item.channel_key)}>删除渠道</Button>
                                    </>} />
                                </Table>
                            </div>
                        }
                    }}
                    pagination={{
                        onChange: (page, size) => { handlePageTo(page, size) },
                        total: data.total_entries,
                        current: data.page_number,
                        pageSize: data.page_size || 0,
                        showQuickJumper: true
                    }}>
                    <Column title="版本名" dataIndex="version_name" key="version_name" width={80} align="center" />
                    <Column title="版本号" dataIndex="version_code" key="version_code" width={100} align="center" />
                    <Column title="发行日期" dataIndex="released_at" key="released_at" width={150} render={(text) => dayjs(`${text}`).add(8, "hour").format("YYYY-MM-DD")} />
                    <Column title="下载地址" dataIndex="uri" key="uri" align="center" width={100} render={(value) => <Popover content={<QRCode value={value}></QRCode>}><a href={value} target="_blank"><CloudDownloadOutlined /></a></Popover>} />
                    <Column title="状态" dataIndex="status" key="status" width={300} render={(value, item: any) => <Radio.Group onChange={(e) => handleUpdateField(item.version_code, "status", e.target.value)} size="small" value={value} buttonStyle="solid">
                        <Radio.Button value="pending">审核中</Radio.Button>
                        <Radio.Button value="released">已上线</Radio.Button>
                        <Radio.Button value="unavailable">下架</Radio.Button>
                        <Radio.Button value="pending_cheating">审核模式</Radio.Button>
                    </Radio.Group>} />
                    <Column title="强制升级到最新版" align="center" width={150} dataIndex="deprecated" key="deprecated" render={(value, item: any) => <Switch onChange={(v) => handleUpdateField(item.version_code, "deprecated", v)} checked={value} checkedChildren="是" unCheckedChildren="否" size="small" ></Switch>} />
                    <Column title="手动接受会员协议" width={150} dataIndex="manual_membership_agreement" key="manual_membership_agreement" render={(value, item: any) => <Switch onChange={(v) => handleUpdateField(item.version_code, "manual_membership_agreement", v)} checked={value} unCheckedChildren="否" checkedChildren="是" size="small" ></Switch>} />

                    <Column title="版本描述" dataIndex="description" key="description" render={text => <pre style={{ whiteSpace: "break-spaces", maxHeight: 150, fontSize: 11 }}>{text}</pre>} />
                    <Column title="操作" width={350} render={(text, item: any) => <>
                        <Button type="link" size="small" onClick={() => handleNewVersionChannelAdd(item.version_code)}>新增渠道</Button>
                        <Popover trigger="click" content={<div>
                            {/* <Select style={{ minWidth: 200 }} onChange={(channel) => handleVersionUpdateVersionSubmit(item.version_code, channel)} showSearch optionLabelProp="label" options={channels!.map(item => {
                                return { label: item.name, key: item.key, value: item.key }
                            })}></Select> */}
                        </div>}>
                            <Button type="link" size="small" icon={<RocketOutlined />}>模拟更新</Button>
                        </Popover>
                        <Button type="link" size="small" danger icon={<DeleteOutlined />} onClick={() => handleDrop(item.version_code)}>删除版本</Button>
                    </>} />
                </Table>
            }
        </div>
    </div>
}