import { Menu } from 'antd';
import { ApiOutlined, BgColorsOutlined, CommentOutlined, SettingOutlined } from '@ant-design/icons';
import { useLocation, Link } from "react-router-dom";
import { useGlobalState } from '../../components/global';
import configFunc from '../../config';
// import RequireRolesOr from '../../components/require_roles_or'

function SideMenu() {
    const location = useLocation()

    const pathName = location.pathname.split("/").slice(0, 3).join("/")
    const fullPathName = location.pathname
    const { user, currentTenant } = useGlobalState()

    const handleClickTestClient = () => {
        window.open(`${configFunc.HttpEndpoint("/api/customer-service/test-client")}?token=${user.token}&tenant_meta=${currentTenant}`, '在线客服', `width=${400},height=${800},left=${document.documentElement.clientWidth - 400},top=150,toolbar=no,menubar=no,resizable=no,location=no,status=no`);
    }

    return <Menu style={{ width: 256 }} defaultSelectedKeys={[pathName, fullPathName]} mode="inline" >
        {/* <RequireRolesOr require={["acupoint:role::operator", "acupoint:role::finance"]}> */}
        <Menu.ItemGroup key="chat" title="在线客服">
            <Menu.Item key="/customer-service/chat" icon={<CommentOutlined />}>
                <Link to="/customer-service/chat">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>在线客服</span>
                    </div>
                </Link>
            </Menu.Item>
        </Menu.ItemGroup>
        {/* </RequireRolesOr> */}
        <Menu.ItemGroup key="setting" title="设置">
            <Menu.Item key="/customer-service/setting/profile" icon={<SettingOutlined />}>
                <Link to="/customer-service/setting/profile">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>系统设置</span>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key="/customer-service/setting/theme" icon={<BgColorsOutlined />}>
                <Link to="/customer-service/setting/theme">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>主题设置</span>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key="/customer-service/setting/callbacks" icon={<ApiOutlined />}>
                <Link to="/customer-service/setting/callbacks">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>回调设置</span>
                    </div>
                </Link>
            </Menu.Item>
        </Menu.ItemGroup>

        <Menu.ItemGroup key="chat-client" title="在线客服">
            <Menu.Item key="/customer-service/test-client" icon={<CommentOutlined />}>
                <a onClick={handleClickTestClient}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>用户端（测试用）</span>
                    </div>
                </a>
            </Menu.Item>
        </Menu.ItemGroup>
    </Menu >
}



export default SideMenu