import OrderedMedia from "../OrderedMedia";
import {Form, Input} from "antd";
import CustomQuill from "../../../../components/CustomQuill";
import ContentImageUploader from "../../../components/ContentImageUploader";
import {useGlobalState} from "../../../../components/global";
import React from "react";

export default function HistoryInput({prefix, index, form, listPathName, changePrefix}: any) {

    const { jsonRequest, currentTenant } = useGlobalState()
    
    const imgUploadApi = async (fd: any) => {
        return await ContentImageUploader(jsonRequest, currentTenant, fd.get('file'));
    }
    
    const onChange = () => {
        form.setFieldValue([...changePrefix.slice(0, -1), 'change'], true);
    }

    return <OrderedMedia index={index} form={form} listPathName={listPathName}>
        <Form.Item label='标题' name={[...prefix, 'title']} initialValue="">
            <Input onChange={onChange}/>
        </Form.Item>
        <Form.Item label='作者' name={[...prefix, 'author']} initialValue="">
            <Input onChange={onChange}/>
        </Form.Item>
        <Form.Item label='朝代' name={[...prefix, 'dynasty']} initialValue="">
            <Input onChange={onChange}/>
        </Form.Item>
        <Form.Item label='时间' name={[...prefix, 'times']} initialValue="">
            <Input onChange={onChange}/>
        </Form.Item>
        <Form.Item label='详情' name={[...prefix, 'description']} initialValue="">
            <CustomQuill  onChange={onChange} imgUploadApi={imgUploadApi}/>
        </Form.Item>
    </OrderedMedia>
}