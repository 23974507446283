import RichTextEditor from "quill-react-commercial";
import "quill-react-commercial/lib/index.css";
import { useRef, useState } from "react";

export default function CustomQuill({
                                        placeholder = "请输入...",
                                        imgUploadApi,
                                        value,
                                        onChange,
                                        quillRef,
                                        ...others
                                    }: any) {
    const quill: any = useRef();
    const [hasUpdate, setHasUpdate]: any = useState(false);

    const modules = {
        table: {},
        codeHighlight: true,
        imageHandler: { imgUploadApi },
        toolbarOptions: [
            ["undo", "redo", "clean"],
            [{ font: ["wsYaHei", "songTi", "serif", "arial"] }, { size: ["16px", "10px", "12px", "14px", "18px", "36px"] }],
            [{ color: [] }, { background: [] }],
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }, { list: "check" }, { indent: "-1" }, { indent: "+1" }, { align: [] }],
            ["blockquote", "code-block", "link", "image", { script: "sub" }, { script: "super" }, "table", "divider"],
        ],
    };

    const onEditorChange = (v: any) => {
        setHasUpdate(true);
        onChange && onChange(quill.current.getSemanticHTML(), quill.current.getText());
    };

    const getQuill = (quillIns: any) => {
        quill.current = quillIns;
        quill.current.blur();
        quillRef && (quillRef.current = quillIns);
    };

    return <RichTextEditor modules={modules} placeholder={placeholder} i18n="zh"
                           {...others} onChange={onEditorChange} getQuill={getQuill}
                           content={hasUpdate ? undefined : (value || <p></p>)} />;
}