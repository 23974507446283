import { ActionType, ProFormInstance, ProTable } from "@ant-design/pro-components";
import { Button, Cascader, Form, InputNumber, message, Modal, Popconfirm, Select, Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import GlobalWrap from "../../components/globalWrap";
import { useGlobalState } from "../../components/global";
import { useEffect, useRef, useState } from "react";
import ContentCateList from "./components/ContentCateList";
import ContentForm from "./components/ContentForm";
import { useParams } from "react-router-dom";
import ContentAuditForm from "./components/ContentAuditForm";
import RefSelector from "./components/RefSelector";
import EditableCell from "./components/EditableCell";
import { handleContentValues } from "./components/ContentFormat";
import ContentJson from "./components/ContentJson";

export default function Contents() {
    const { jsonRequest, currentTenant } = useGlobalState()
    const [form] = Form.useForm();
    const [auditForm] = Form.useForm();
    const tableRef = useRef<ActionType>();
    const formRef = useRef<ProFormInstance>();
    const [auditStates, setAuditStates]: any = useState()
    const [tenants, setTenants] = useState<any>([]);
    const [categories, setCategories]: any = useState([]);
    const [refs, setRefs]: any = useState([])
    const { code } = useParams()
    let modal: any;

    useEffect(() => {
        getTenants();
        getAuditState();
        tableRef.current?.reload();
    }, [currentTenant]);

    useEffect(() => {
        formRef.current?.resetFields();
        changeSystemCode(code);
    }, [code]);

    const getTenants = () => {
        jsonRequest.post("/api/request", {
            path: "/v1/systems/tenant",
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {},
        }).then((resp: any) => resp.json())
            .then(({ data }) => {
                setTenants(data)
            })
    }

    const getAuditState = () => {
        jsonRequest.post("/api/request", {
            path: "/v1/enums",
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: { type: 'audit_status' },
        }).then((resp: any) => resp.json())
            .then(({ data }) => {
                setAuditStates(data)
            })
    }

    const changeSystemCode = (code: any) => {
        // setSystemCode(code);
        // @ts-ignore
        formRef.current.setFieldValue('systemCode', code)
        if (!code) {
            setRefs([])
            setCategories([])
            // @ts-ignore
            formRef.current?.submit();
            return;
        }
        getGroupTrees(code);
        getGroupRefs(code);
    }

    const onValuesChange = ({ systemCode }: any) => {
        if (systemCode) changeSystemCode(systemCode);
    }

    const getGroupRefs = (systemCode: any) => {
        jsonRequest.post("/api/request", {
            path: `/v1/contents/group-refs`,
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: { systemCode },
        }).then((resp: any) => resp.json())
            .then(({ data }) => {
                setRefs(data)
            })
    }

    const getGroupTrees = (code: any) => {
        jsonRequest.post("/api/request", {
            path: "/v1/content/cates/group-trees",
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: { systemCode: code },
        }).then((resp: any) => resp.json())
            .then(({ data }) => {
                setCategories(data);
                formRef.current?.submit();
            })
    }

    const getCategoryChildren = (cate: any) => {
        return cate?.map((item: any) => ({
            value: item.id,
            label: item.cateName,
            children: getCategoryChildren(item.sons)
        }))
    }

    const columns: any[] = [
        {
            title: '所属体系',
            dataIndex: 'systemCode',
            align: 'center',
            initialValue: code,
            hideInTable: true,
            renderFormItem: () => <Select allowClear
                disabled={!!code}
                options={tenants.map((e: any) => ({
                    value: e.systemCode,
                    label: e.systemName
                }))} />
        },
        {
            title: '排序号',
            dataIndex: 'index',
            align: 'center',
            sorter: true,
            search: false,
            width: 100,
            render: (_: any, record: any) => <EditableCell id={record.id} index={record.index || 0}
                afterChange={() => tableRef.current?.reload()} />
        },
        {
            title: '原ID',
            dataIndex: 'sourceId',
            align: 'center',
            width: 100,
            renderFormItem: () => <InputNumber min={0} precision={0} />
        },
        {
            title: '标题',
            dataIndex: 'contentName',
            align: 'center',
            search: false,
            width: 300,
        },
        {
            title: '分类',
            dataIndex: 'cateList',
            align: 'center',
            search: false,
            render: (_: any, record: any) => <ContentCateList cateList={record.cateList} />
        },
        {
            title: '状态',
            dataIndex: 'state',
            align: 'center',
            render: (_: any, record: any) =>
                <Tag color={record.state === 1 ? 'success' : record.state === 2 ? 'warning' : 'error'}>
                    {record.state === 1 ? '启用' : record.state === 2 ? '禁用' : '已删除'}
                </Tag>,
            width: 100,
            renderFormItem: () => <Select allowClear
                options={[
                    { label: '启用', value: 1 },
                    { label: '禁用', value: 2 },
                    { label: '已删除', value: 3 },
                ]} />,
        },
        {
            title: '审核状态',
            dataIndex: 'auditState',
            align: 'center',
            render: (_: any, { auditState }: any) =>
                <Tag
                    color={auditState === 'wait' ? 'warning' : auditState === 'going' ? 'processing' : auditState === 'pass' ? 'success' : 'error'}>
                    {auditStates.find((e: any) => e.enumKey === auditState)?.enumName || '-'}
                </Tag>,
            width: 100,
            renderFormItem: () => <Select allowClear
                options={auditStates?.map((e: any) => ({
                    value: e.enumKey,
                    label: e.enumName
                }))} />,
        },
        {
            title: '关键词',
            dataIndex: 'keyword',
            hideInTable: true,
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            search: false,
            align: 'center',
            width: 200,
        },
        {
            title: '操作',
            key: 'option',
            search: false,
            align: 'center',
            width: 350,
            render: (_: any, record: any) => <>
                <Button type="link" onClick={() => handelContentAudit(record)}>审核</Button>
                {/*<Button type="link">查看</Button>*/}
                <Button type="link" onClick={() => handleContentForm(record)}>修改</Button>
                {(record.state === 1 || record.state === 2) &&
                    <Button type="link" danger={record.state === 1}
                        onClick={() => handleState(record)}>{record.state === 1 ? '禁用' : '启用'}</Button>}
                {record.state !== 3 && <Popconfirm
                    title="确定删除？"
                    onConfirm={() => handleDelete(record)}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="link" danger>删除</Button>
                </Popconfirm>}
                {record.state === 3 && <Popconfirm
                    title="确定还原？"
                    onConfirm={() => handleRestore(record)}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="link">还原</Button>
                </Popconfirm>}
                <Button type="link" onClick={() => handleContentJsonForm(record.id)}>json</Button>
            </>,
        }
    ];

    const getOtherSearchColumns = () => {
        const catColumns = categories.map((e: any, index: any) => ({
            title: e.groupName,
            key: ['cateIds', index],
            hideInTable: true,
            renderFormItem: () => <Cascader changeOnSelect showCheckedStrategy={Cascader.SHOW_CHILD}
                options={getCategoryChildren(e.trees)} />
        }))

        const refColumns = refs.map((e: any, index: any) => ({
            title: e.fieldName,
            key: ['refIds', index],
            hideInTable: true,
            renderFormItem: () => <RefSelector content={e} />
        }));
        return [...catColumns, ...refColumns];
    }

    const handleState = (record: any) => {
        const { id, state } = record;
        jsonRequest.post("/api/request", {
            path: "/v1/contents/state",
            method: "post",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: { id, state: state === 2 },
        }).then((resp: any) => resp.json())
            .then(() => {
                // @ts-ignore
                tableRef.current.reload();
                message.success(`${state ? '禁用' : '启用'}成功`)
            })
    }

    const handleDelete = (record: any) => {
        jsonRequest.post("/api/request", {
            path: `/v1/contents/${record.id}`,
            method: "delete",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: { id: record.id },
        }).then((resp: any) => resp.json())
            .then(() => {
                // @ts-ignore
                tableRef.current.reload();
                message.success(`删除成功`)
            })
    }

    const handleRestore = (record: any) => {
        jsonRequest.post("/api/request", {
            path: `/v1/contents/${record.id}/restore`,
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {},
        }).then((resp: any) => resp.json())
            .then(() => {
                // @ts-ignore
                tableRef.current.reload();
                message.success(`还原成功`)
            })
    }

    const extendArray = (arr: []) => {
        if (!arr) return [];
        let result: any[] = [];
        for (let i = 0; i < arr.length; i++) {
            if (Array.isArray(arr[i])) {
                // 如果是数组，则递归调用 flattenArray 函数  
                result = [...result, ...extendArray(arr[i])];
            } else if (!result.includes(arr[i])) {
                // 如果不是数组，则直接添加到结果数组中  
                arr[i] && (result = [...result, arr[i]]);
            }
        }
        return result;
    }

    const handelContentAudit = (detail: any) => {
        Modal.confirm({
            width: 600,
            title: '审核',
            content: <GlobalWrap><ContentAuditForm form={auditForm} auditStates={auditStates}
                detail={detail} /></GlobalWrap>,
            onOk: async (close) => {
                let values = await auditForm.validateFields()
                await jsonRequest.post("/api/request", {
                    path: `/v1/contents/audit`,
                    method: "post",
                    application_key: "zy:application::knowledge-foundation",
                    tenant_meta: currentTenant,
                    data: values
                })
                auditForm.resetFields();
                // @ts-ignore
                tableRef.current.reload();
                close()
                return false
            },
            onCancel: (close) => {
                auditForm.resetFields();
                close();
            }
        });
    }

    const formatFormValues = (values: any) => {
        const { cateIds, parentIds, medias, ownerId, ownerType, ...others } = values || {};
        const content = { id: ownerId, cateIds: extendArray(cateIds), parentIds: extendArray(parentIds), ...others }
        const media = { ownerId, ownerType, medias }
        return { content, media };
    }

    const handleFormCancel = () => {
        modal?.destroy();
    }

    const handleContentFormValidate = (values: any) => {
        const { systemCode, contentName } = values;
        if (systemCode && contentName) return true;
        if (!systemCode) message.error('知识体系不能为空')
        else if (!contentName) message.error('内容名称不能为空')
        return false;
    }

    const handleFormOk = async () => {
        const values = form.getFieldsValue();
        console.log(values)
        if (!handleContentFormValidate(values)) return false;
        handleContentValues(values)
        const { content, media }: any = formatFormValues(values);
        const { data } = await jsonRequest.post("/api/request", {
            path: `/v1/contents`,
            method: values?.ownerId ? "put" : "post",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: content,
        }).then(resp => resp.json())
        if (!data?.message) {
            if (!values?.ownerId) {
                message.success(`新增成功，请编辑详细信息`)
                form.setFieldsValue({ ownerId: data.contentId, ownerType: data.type })
                return false;
            }
        } else {
            message.error(data.message);
            return false;
        }
        try {
            const { mediaData } = await jsonRequest.post("/api/request", {
                path: `/v1/contents/medias`,
                method: "post",
                application_key: "zy:application::knowledge-foundation",
                tenant_meta: currentTenant,
                data: media,
            }).then(resp => resp.json())

            if (mediaData?.message) {
                message.error(mediaData.error);
                return false;
            }
            message.success(`编辑成功`)
        } catch (e) {
            console.error(e)
            message.error(`出现意料之外的错误`)
            return false;
        }
        modal?.destroy();
    }

    const handleContentForm = (detail: any) => {
        modal = Modal.confirm({
            width: '60vw',
            title: <span>内容编辑</span>,
            style: { position: 'relative' },
            content: <GlobalWrap>
                <ContentForm form={form} tenants={tenants} detail={detail} type={detail.id ? 'edit' : 'add'}
                    onOk={handleFormOk} onCancel={handleFormCancel} />
            </GlobalWrap>,
            footer: null,
            afterClose: () => {
                // @ts-ignore
                tableRef.current.reload();
            }
        })
    }

    const handleContentJsonForm = (id: any) => {
        Modal.confirm({
            width: '50vw',
            title: <span>内容JSON</span>,
            content: <GlobalWrap>
                <ContentJson ownerId={id} ownerType='content' />
            </GlobalWrap>,
        })
    }

    const getCateIds = (cateIds: any) => {
        if (!cateIds) return [];
        const arr = cateIds?.map((e: any) => Array.isArray(e) ? e[e.length - 1] : e)
        return arr.filter((e: any) => e !== null && e !== undefined && e !== '')
    }

    const request = async (params: any, sort: any) => {
        const orderBy = !sort?.index ? undefined : sort.index === 'descend' ? 'index desc' : 'index asc';
        const { current, cateIds, refIds, ...others } = params;
        const result = await jsonRequest.post("/api/request", {
            path: "/v1/contents/paged",
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {
                ...others,
                orderBy,
                pageNumber: current,
                refIds: extendArray(refIds),
                cateIds: getCateIds(cateIds),
            },
        }).then((resp: any) => resp.json())
        const { data } = result;
        return { data: data.list, success: result.code === 0, total: data.total };
    }

    return <>
        <h1>内容维护-{tenants?.find((e: any) => e.systemCode === code)?.systemName || '全部内容'}</h1>
        <ProTable
            rowKey="id"
            actionRef={tableRef}
            formRef={formRef}
            form={{ onValuesChange }}
            cardBordered
            columns={[...columns, ...getOtherSearchColumns()]}
            request={request}
            pagination={{ defaultPageSize: 10, showSizeChanger: true }}
            search={{ span: 6, defaultCollapsed: false }}
            toolBarRender={() => [
                <Button
                    key="button"
                    icon={<PlusOutlined />}
                    onClick={() => handleContentForm({ systemCode: code })}
                    type="primary"
                >
                    新增
                </Button>,
            ]}
        />
    </>
}