import { FormOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons"
import { Divider, Switch, Button, Form, message, Modal } from "antd"
import { Color, SketchPicker } from "react-color"
import { FormScreenChannel } from "./FormScreenChannel"
import { FormScreenGroup } from "./FormScreenGroup"
import { useGlobalState } from "../../components/global"
import { useState } from "react"
import UploadComponent from "../../components/UploadComponent"

declare var window: any;
export type ScreenItemProps = {
    appid: string,
    // 默认屏的数据
    launchScreenID: number,
    // 默认或者渠道的数据
    defaultOrChannelItem: any,
    // 操作之后的重新加载
    handleReload: () => void,
    zoomRatio: number,
    // 模拟现实屏幕参数
    simScreen: { width: number, height: number, model: string },
    // 是否是渠道
    isChannel?: boolean,
    // 版本数据
    versions: any
}

export function ScreenItem({ appid, launchScreenID, defaultOrChannelItem, handleReload, zoomRatio, simScreen, isChannel = false, versions }: ScreenItemProps) {

    const { jsonRequest, currentTenant } = useGlobalState()
    const [backgroundColorSelectorValue, setBackgroundColorSelectorValue] = useState<Color | null>()

    const [colorPickerShow, setColorPickerShow] = useState<boolean>(false)

    const handleBackgroundColorChange = (launch_screen_id: any, channel_id: string, color: any) => {
        const apiPath = !!isChannel ?
            `/api/launch_screen/${appid}/${launch_screen_id}/channel/${channel_id}/background_color`
            :
            `/api/launch_screen/${appid}/${launch_screen_id}/background_color`

        jsonRequest.application("put", "zy:services::release", apiPath, { value: color })
            .finally(() => {
                handleReload()
            })
    }

    const handleEnableChange = (launch_screen_id: number, channel_id: number | null, enable: boolean) => {
        const apiPath = !!isChannel ?
            `/api/launch_screen/${appid}/${launch_screen_id}/channel/${channel_id}/enable`
            :
            `/api/launch_screen/${appid}/${launch_screen_id}/enable`

        jsonRequest.application("put", "zy:services::release", apiPath, { value: enable })
            .finally(() => {
                setColorPickerShow(false)
                handleReload()
            })
        // jsonRequest.application("put", "zy:services::release", `/api/launch_screen/${appid}/${launch_screen_id}`, { ...values, appid })
        // this.state.channel.push("change-enable", { channel_key, launch_screen_id, enable })
        //     .receive("ok", () => {

        //     })
    }

    const [addChannelFormRef] = Form.useForm()

    // 编辑渠道
    const handleClickEditChannel = (channel: any) => {
        Modal.confirm({
            title: '修改渠道',
            content: <FormScreenChannel form={addChannelFormRef} initial={channel} />,
            onOk: (close) => {
                const form = addChannelFormRef
                form
                    .validateFields()
                    .then((values: any) => {
                        values = { ...values, action: { ...values.action, data: JSON.parse(values.action.data || "{}") } }
                        jsonRequest.application("put", "zy:services::release", `/api/launch_screen/${appid}/channel/${channel.id}`, values)
                            .then(({ ok }) => {
                                if (ok) {
                                    close()
                                    message.success("修改成功");
                                    handleReload();
                                    form.resetFields();
                                }
                            })
                    })
                    .catch((info: any) => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }


    const [screenFormRef] = Form.useForm()

    // 编辑渠道
    const handleClickEditScreen = (screen: any) => {
        Modal.confirm({
            width: 600,
            title: '修改开屏页',
            content: <FormScreenGroup versions={versions} screen={screen} form={screenFormRef} appid={appid} />,
            onOk: (close) => {
                const form = screenFormRef
                form
                    .validateFields()
                    .then((values: any) => {
                        values = { ...values, action: { ...values.action, data: JSON.parse(values.action.data || "{}") } }

                        jsonRequest.application("put", "zy:services::release", `/api/launch_screen/${appid}/${screen.id}`, values)
                            .then(({ ok }) => {
                                if (ok) {
                                    close()
                                    message.success("修改成功")
                                    form.resetFields();
                                    handleReload()
                                }
                            })
                    })
                    .catch((info: any) => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }


    const handleClickDeleteChannel = (launch_screen_id: number, channel_id: number) => {
        Modal.confirm({
            title: '删除确认',
            content: <>
                删除无法恢复，确认继续？
            </>,
            onOk: (close) => {
                jsonRequest.application("delete", "zy:services::release", `/api/launch_screen/${appid}/${launch_screen_id}/channel/${channel_id}`, {})
                    .then(({ ok }) => {
                        if (ok) {
                            close()
                            message.success("删除成功")
                            handleReload()
                        }
                    })
                return false
            }
        });
    }

    // 上传封面成功后
    const handleUploadSuccess = (fileList: any) => {
        const files = fileList.map((e: any) => ({
            ...e,
            url: e.url.startsWith('http') ? e.url : `${window.ATTACHMENT_URL}/api/oss/${e.url}`,
            objName: e.url.startsWith('http') ? e.url : `${window.ATTACHMENT_URL}/api/oss/${e.url}`
        }));

        const file = files[0]
        jsonRequest.application("put", "zy:services::release", `/api/launch_screen/${appid}/${launchScreenID}/content`, {
            content: {
                uri: file.url,
                filename: file.originFileObj.name,
                size: file.originFileObj.size,
                mime: file.originFileObj.type,
            }
        }).finally(handleReload)
    }

    const backgroundColorValue = backgroundColorSelectorValue ?? defaultOrChannelItem.content?.background_color?.hex ?? "#000000";

    return <div className="launch-item" key={`l-${launchScreenID}-channels-${defaultOrChannelItem.channel_key || "main"}`}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ backgroundColor: backgroundColorValue, height: 20, width: 20, cursor: "pointer" }}
                    onClick={() => setColorPickerShow(true)}>
                </div>
                <Divider type="vertical" ></Divider>
                <span style={{ width: 150 * zoomRatio, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{defaultOrChannelItem.channel_key || "默认"}</span>
            </div>
            <div>
                <Switch checked={defaultOrChannelItem.enable} checkedChildren="启用" unCheckedChildren="禁用" size="small" onChange={(checked) => handleEnableChange(launchScreenID, defaultOrChannelItem.id, checked)}></Switch>

                {!isChannel && <>
                    <Divider type="vertical" />
                    <Button type="link" icon={<FormOutlined />} size="small" onClick={() => handleClickEditScreen(defaultOrChannelItem)}>编辑</Button>
                </>}
                {!!isChannel && <>
                    <Divider type="vertical" />
                    <Button type="link" icon={<FormOutlined />} size="small" onClick={() => handleClickEditChannel(defaultOrChannelItem)}>编辑</Button>
                    <Divider type="vertical" />
                    <Button type="link" icon={<DeleteOutlined />} danger size="small" onClick={() => handleClickDeleteChannel(defaultOrChannelItem.launch_screen_id, defaultOrChannelItem.id)}></Button>
                </>}
            </div>
            {colorPickerShow && <div style={{ position: "absolute", zIndex: 10 }}>
                <SketchPicker color={backgroundColorSelectorValue ?? defaultOrChannelItem?.content?.background_color?.rgb ?? "00000000"} onChange={((c) => setBackgroundColorSelectorValue(c.hex))} onChangeComplete={(c: any) => handleBackgroundColorChange(launchScreenID, defaultOrChannelItem.id, c)} />
                <Button size="small" block onClick={() => setColorPickerShow(false)}>取消</Button>
            </div>
            }
        </div>

        <div className="delay-close">
            {defaultOrChannelItem.remain}秒
        </div>

        <div style={{
            display: "flex", flexDirection: "column",
            width: simScreen.width * zoomRatio,
            height: simScreen.height * zoomRatio
        }}>
            <UploadComponent path={[currentTenant, "launch_screen"].join("/")} onChange={(fileList: any) => handleUploadSuccess(fileList)} name="cover" className="pic-uploader" maxCount={1} listType="picture-card" showUploadList={false}>
                {
                    defaultOrChannelItem.content?.uri ?
                        <div className="cover" style={{
                            background: `url(${defaultOrChannelItem.content?.uri}) center no-repeat`,
                            backgroundSize: "contain",
                            backgroundColor: backgroundColorValue,
                            height: "100%",
                            width: "100%"
                        }}>
                        </div> :
                        <div style={{ backgroundColor: backgroundColorValue, height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{textShadow: "1px 1px 1px #fff"}}>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>拖到这里或点击</div>
                            </div>
                        </div>
                }
            </UploadComponent>
        </div>
    </div >

}