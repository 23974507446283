import { Select, InputNumber, Input, Form } from "antd";
import { useReleaseState } from "../state/release_state";

export function FormScreenChannel({ form, initial }: any) {

    const { channels } = useReleaseState()

    return channels && <Form form={form} name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} autoComplete="off">
        <Form.Item label="渠道" name="channel_key" initialValue={initial?.channel_key} rules={[{ required: true, message: '必填' }]}>
            <Select disabled={!!initial}>
                {channels.map(channel => <Select.Option key={channel.key} value={channel.key}>
                    {channel.name} <span style={{ color: "grey" }}>{channel.key}</span>
                </Select.Option>)}
            </Select>
        </Form.Item>
        <Form.Item label="可跳过延迟" name={["data", "remain"]} initialValue={initial?.remain} rules={[{ required: true, message: '必填' }]} >
            <InputNumber min={1} addonAfter="秒" />
        </Form.Item>
        <Form.Item label="行为类型" name={["action", "type"]} initialValue={initial?.action?.type} rules={[{ required: true, message: '必填' }]} >
            <Select >
                <Select.Option value="app_router">APP路由</Select.Option>
                <Select.Option value="url">URL</Select.Option>
                <Select.Option value="wechat_miniprogram">微信小程序</Select.Option>
            </Select>
        </Form.Item>
        <Form.Item label="行为参数" name={["action", "data"]} initialValue={initial?.action?.data && JSON.stringify(initial?.action?.data) || "{}"} rules={[{ required: true, message: '必填' }]}
            help="由于每个APP适配的参数不一样，所以这里是自定义的格式">
            <Input.TextArea autoSize style={{ minHeight: 100 }} />
        </Form.Item>
    </Form>
}