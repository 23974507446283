import { Form, Input, Image, UploadFile } from "antd";
import KnowledgeUploader from "../../../components/KnowledgeUploader";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useGlobalState } from "../../../../components/global";
import { useWatch } from "antd/lib/form/Form";

export default function PictureInput({
    form,
    prefix = [],
    fullPrefix = [],
    labelPre = '',
    field,
    name,
    fileNamePath,
}: any) {
    const { isRequired } = field || {};
    const { currentTenant } = useGlobalState()
    const [previewOpen, setPreviewOpen]: any = useState(false);
    const [previewImage, setPreviewImage]: any = useState('');
    const [fileList, setFileList]: any = useState<UploadFile[]>([])
    const backName = name ? name : [...fullPrefix, "picturePath"];
    const watchPath = useWatch(backName)

    useEffect(() => {
        const path = form.getFieldValue(backName)
        const file = form.getFieldValue([...fullPrefix, "file"])
        // 只用于初始化file，所以file存在时不做处理
        if (file || !path) return;

        const list = [{ url: path, uid: -(new Date()).getTime(), status: 'done' }];
        form.setFieldValue([...fullPrefix, "file"], list)
        setFileList(list)
    }, [watchPath]);

    const handlePreview = async (file: UploadFile) => {
        setPreviewImage(file.url);
        setPreviewOpen(true);
    };

    const handleFileChange = (fileList: any) => {
        setFileList(fileList)
        const [file] = fileList;
        form.setFieldValue(backName, file?.url || '')
        file?.status === 'done' && callbackFileName(file);
    }

    const callbackFileName = (file: any) => {
        if (!fileNamePath || !file) return;
        const fileName = form.getFieldValue(fileNamePath);
        if (fileName) return;
        const name = file.name.substring(0, file.name.lastIndexOf("."));
        form.setFieldValue(fileNamePath, name);
    }

    return <>
        <Form.Item label={`${labelPre}图片`} rules={[{ required: isRequired, message: '不能为空' }]}
            name={[...prefix, "file"]}>
            <KnowledgeUploader path={[currentTenant, "product"].join("/")} type="picture" maxCount={1} value={fileList}
                listType="picture-card" accept='image/*' onPreview={handlePreview}
                onChange={handleFileChange}>
                {fileList.length === 0 &&
                    <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>拖到这里</div>
                    </div>}
            </KnowledgeUploader>
        </Form.Item>
        <Form.Item hidden label={`图片`} name={name || [...prefix, "picturePath"]}>
            <Input />
        </Form.Item>
        {previewImage && <Image
            wrapperStyle={{ display: 'none' }}
            preview={{
                visible: previewOpen,
                onVisibleChange: (visible) => setPreviewOpen(visible),
                afterOpenChange: (visible) => !visible && setPreviewImage(''),
            }}
            src={previewImage}
        />}
    </>
}