import { useGlobalState } from "../../../../components/global";
import ContentImageUploader from "../../../components/ContentImageUploader";
import OrderedMedia from "../OrderedMedia";
import { Form } from "antd";
import CustomQuill from "../../../../components/CustomQuill";

export default function HistoryTranslateInput({ prefix, index, form, listPathName, changePrefix }: any) {

    const { jsonRequest, currentTenant } = useGlobalState()

    const imgUploadApi = async (fd: any) => {
        return await ContentImageUploader(jsonRequest, currentTenant, fd.get("file"));
    };

    const onChange = () => {
        form.setFieldValue([...changePrefix.slice(0, -1), 'change'], true);
    }

    return <OrderedMedia index={index} form={form} listPathName={listPathName}>
        <Form.Item label="古文" name={[...prefix, "ancient"]} initialValue={""}>
            <CustomQuill imgUploadApi={imgUploadApi} onChange={onChange} />
        </Form.Item>
        <Form.Item label="白话文" name={[...prefix, "modern"]} initialValue={""}>
            <CustomQuill imgUploadApi={imgUploadApi} onChange={onChange} />
        </Form.Item>
    </OrderedMedia>;
}