import { ActionType, ProTable } from "@ant-design/pro-components";
import { Button, Form, Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useGlobalState } from "../../components/global";
import { useEffect, useRef } from "react";

export default function Library(params: any) {
    const { user, navActiveKey, jsonRequest, currentTenant, signOut, setTenant } = useGlobalState();
    const [form] = Form.useForm();
    const tableRef = useRef<ActionType>();

    const columns: any[] = [
        {
            title: "名称",
            dataIndex: "systemName",
            align: "center",
        },
        {
            title: "编码key",
            dataIndex: "systemCode",
            search: false,
            copyable: true,
            align: "center",
        },
        {
            title: "是否关联当前租户",
            dataIndex: "ownerStatus",
            search: false,
            align: "center",
            width: 200,
            render: (_: any, record: any) => <Tag
              color={record.ownerStatus ? "success" : "error"}>{record.ownerStatus ? "是" : "否"}</Tag>,
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            search: false,
            align: "center",
            width: 200,
        },
        {
            title: "操作",
            key: "option",
            search: false,
            align: "center",
            width: 300,
            render: (_: any, record: any) => <>
                {/*<Button type="link" onClick={() => handleAddOrEdit(record)}>修改</Button>*/}
                {/*<Button type="link"*/}
                {/*        onClick={() => handleTenant(record)}>{record.ownerStatus ? '取消关联当前租户' : '关联当前租户'}</Button>*/}
                {/*<Popconfirm title="嗯？" okText="嗯！" cancelText="嗯~" onConfirm={() => onDelete(record.id)}>*/}
                {/*    <Button type="link" danger>删除</Button>*/}
                {/*</Popconfirm>*/}
            </>,
        },
    ];

    const request = async (params: any, sort: any, filter: any) => {
        const { current, pageSize, systemName } = params;
        const result = await jsonRequest.post("/api/request", {
            path: "/api/v1/auth/user",
            method: "get",
            application_key: "zy:services::user-library",
            tenant_meta: currentTenant,
            data: {
                keyword: systemName,
                pageNumber: current,
                pageSize,
            },
        }).then((resp: any) => resp.json());
        const { data } = result;
        return { data: data.list, success: result.code === 0, total: data.total };
    };
    
    return <>
        <h1>用户库</h1>
        <ProTable
          rowKey="id"
          actionRef={tableRef}
          cardBordered
          columns={columns}
          request={request}
          pagination={{ defaultPageSize: 10, showSizeChanger: true }}
          search={{ span: 6, defaultCollapsed: false }}
          toolBarRender={() => [<Button
            key="button"
            icon={<PlusOutlined />}
            // onClick={() => handleAddOrEdit({})}
            type="primary"
          >
              新增
          </Button>]}
        />
    </>
}